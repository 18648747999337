.aff-drawer{
  width: 400px;
  z-index: 1100 !important;
}
.aff-image-container{
  height: 100px;
  border: 2px dashed #ccc;
  padding: 5px;
}
.aff-image-container img {
  height: 100%;
  width: 100%;
}
.affiliate-container{
  height: 40px;
  border-radius: 4px;
  margin-bottom: 16px !important;
  padding: 0 16px !important;
}
.affiliate-container-child::before{
  position: absolute;
  height: 2px;
  width: 10px;
  left: -10px;
  top: 19px;
  content: "";
  background-color: #ccc;
}
.tree-vertical-line{
  width: 2px;
  background-color: #ccc;
  left: -10px;
}
.theme-container{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}
.aff-theme-container{
  height: 100px;
  background-color: white;
  cursor: pointer;
  border-radius: 5px;
  border : 2px solid white;
  transition: all 0.5s;
}
.aff-theme-container-active{
  border : 2px solid #01C0C8;
}
.aff-theme-container span{
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: black;
  margin-bottom: 5px;
}
#default-theme span{
  background-color: #073D62;
}
#purple-theme span{
  background-color: #642F91;
}
#green-theme span{
  background-color: #78A22F;
}
#blue-theme span{
  background-color: #2EA3F2;
}
#red-theme span{
  background-color: #EE1B48;
}
/* .drawer-check-list{
  margin: 8px 0 16px 0 !important;
} */