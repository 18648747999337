.divider{
    border: none;
    height: 1px;
    margin: 0;
    flex-shrink: 0;
    background-color: rgba(0, 0, 0, 0.12);
}
.select-with-filter{
    height: 56px;
}
.select-with-filter .select__control{
    height: 100%;
}