@font-face {
  font-family: ApercuBold;
  src: url("./static/fonts/Apercu/Apercu-Bold.otf") format("opentype");
}

@font-face {
  font-family: ApercuLight;
  src: url("./static/fonts/Apercu/Apercu-Light.otf") format("opentype");
}

@font-face {
  font-family: ApercuMedium;
  src: url("./static/fonts/Apercu/Apercu-Medium.otf") format("opentype");
}

@font-face {
  font-family: ApercuRegular;
  src: url("./static/fonts/Apercu/Apercu-Regular.otf") format("opentype");
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  height: 100%;
}

.submit-button {
  height: 50px;
}

button:focus {
  outline: none !important;
}

.container-fluid {
  margin-top: 80px;
  width: calc(100% - 70px) !important;
}

.sq-shadow {
  box-shadow: 2px 2px 10px rgba(1, 49, 50, 0.16) !important;
}

.bg-theme {
  background-color: #01c0c8;
}

.view-sehteq-link:hover {
  text-decoration: none;
}

.drawer-form-title {
  min-height: 64px;
}

.snackbar {
  z-index: 1600 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.plan-container {
  background-color: #f2f2f2;
  height: 40px;
  border-radius: 4px;
}

.beneficiary-close-icon {
  cursor: pointer;
  color: gray;
}

.white-space-pre {
  white-space: pre;
  white-space: -moz-pre;
  white-space: -o-pre;
}

.plan-price-form .plan-title {
  font-size: larger;
  font-weight: bold;
  margin-left: 5px;
}

.affordable-health-cover-not-found {
  font-size: small;
  font-family: "ApercuBold";
  color: red;
  margin-top: 15px;
  margin-left: 5px;
}

.css-1s2u09g-control {
  padding: 7px;
}

.css-1pahdxg-control {
  padding: 7px;
}

.change-secret-btn {
  position: absolute;
  right: 15px;
}
.margin-bottum-affiliate-list{
  margin-bottom: 11px
}